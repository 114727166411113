import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const config = {
  apiKey: "AIzaSyABNYosR3wZCgAj8Yh4bMD7144Df1Cqej8",
  authDomain: "nexowave-d36d1.firebaseapp.com",
  projectId: "nexowave-d36d1",
  storageBucket: "nexowave-d36d1.appspot.com",
  messagingSenderId: "970194399043",
  appId: "1:970194399043:web:83b76772924b7cad8b89f6",
  measurementId: "G-BP3WP6DGJY"
};

// Firebase Class
class FirebaseClass {
    constructor() {
      // Initialize Firebase app
      this.app = initializeApp(config);
  
      // Set up various Firebase services
      this.auth = getAuth(this.app);
      this.db = getFirestore(this.app);
      this.database = getDatabase(this.app);
    }
  }
  
  // Create an instance of the FirebaseClass
  const myFirebase = new FirebaseClass();
  
  // Export the instance
  export default myFirebase;