import { IconButton, List, Typography, colors } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Colors, DrawerWidth } from "../theme";


export const AppbarContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: 4,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 8px',
  position: 'sticky',
  top:0,
  zIndex:100,
  background: Colors.white
}));

export const AppbarHeader = styled(Typography)(() => ({
  padding: "7px",
  flexGrow: 1,
  fontSize: "2.2em",
  fontWeight:"light",
  fontFamily: "Gerline-Regular",
  color:Colors.primary,
  //fontFamily:"cursive",
  "&:hover": {
  },
}));

export const LogoImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "80px",
  height: "50px",
  [theme.breakpoints.down("md")]: {
  },
  [theme.breakpoints.down("sm")]: {
  },
}));

export const ActionIconsContainerMobile = styled(Box)(() => ({
  display: 'flex',
  background: Colors.shaft,
  position: "fixed",
  bottom: 0,
  left: 0,
  width: '100%',
  alignItems: 'center',
  zIndex: 99,
  borderTop: `1px solid ${Colors.border}`
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
  flexGrow: 0,
 
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === "row" ? "flex" : "block",
  flexGrow: 1,
  width: 90,
  justifyContent: "center",
  alignItems: "center",
  // // selected and (selected + hover) states
  // '&& .Mui-selected, && .Mui-selected:hover': {
  //   backgroundColor: 'red',
  //   '&, & .MuiListItemIcon-root': {
  //     color: 'pink',
  //   },
  // },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: Colors.primary,
    '&, & .MuiListItemIcon-root': {
      color: 'white',
    },   
  },
}));

export const DrawerCloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 10,
  left: DrawerWidth,
  zIndex: 1999,
}));

