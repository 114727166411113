
import { Typography } from "@mui/material";
import { ProductMetaWrapper } from "../../styles/industry";
export default function ProductMeta({ product, matches }) {
  return (
    <ProductMetaWrapper>
      <Typography variant={matches ? "h8" : "h7"} lineHeight={2}>
        {product.name}
      </Typography>
    </ProductMetaWrapper>
  );
}