import { ListItemButton, ListItemIcon, ListItemText, capitalize } from "@mui/material";
import { AppbarContainer, AppbarHeader, MyList, LogoImage } from "../../styles/appbar";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { useUIContext } from "../../context/ui";
import SIconP from '@mui/icons-material/Category';
import SIcon from '@mui/icons-material/DesignServices';
import CIcon from '@mui/icons-material/AddIcCall';
import AIcon from '@mui/icons-material/Info';
import React, { useEffect } from 'react';

export default function AppbarDesktop({ matches }) {
  const { setShowSearchBox } = useUIContext();
  useEffect(() => {
    const handleHashChange = () => {
      const { hash } = window.location;
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          window.scrollTo({
            top: element.offsetTop - 105, // Adjust this based on your actual header height
            behavior: "smooth",
          });
        }
      }
    };
  
    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);
  
  
  return (
    <AppbarContainer>
      <LogoImage src="/images/banner/nexoLogo.png"></LogoImage>
      <AppbarHeader variant="h4" fontFamily="Gerline-Regular">nexowave </AppbarHeader>
      <MyList type="row">
      <ListItemButton href="#_products">
          <ListItemIcon sx={{minWidth:"30px"}}>
            <SIconP />
          </ListItemIcon>
          <ListItemText primary="PRODUCTS" disableTypography />
        </ListItemButton>
        <ListItemButton href="#_services">
          <ListItemIcon sx={{minWidth:"30px"}}>
            <SIcon />
          </ListItemIcon>
          <ListItemText primary="SERVICES" disableTypography />
        </ListItemButton>
        <ListItemButton href="#_aboutUs"  >
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <AIcon />
          </ListItemIcon>
          <ListItemText primary="ABOUT US" disableTypography  />
        </ListItemButton>       
        <ListItemButton href="#_contactUs">
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <CIcon />
          </ListItemIcon>
          <ListItemText primary="CONTACT US"  disableTypography />
        </ListItemButton>       
        {/* <ListItemButton onClick={() => setShowSearchBox(true)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
        </ListItemButton> */}
      </MyList>
      {/* <Actions matches={matches} /> */}
    </AppbarContainer>
  );
}
