import React, {useState} from 'react';
import myFirebase from '../../data/firebase';
import { ref, set } from 'firebase/database';
import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Container,
  TextField,
  FormLabel
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import { SubscribeTf, FooterTitle } from "../../styles/contact";

export default function Contact() {
  // Create state variables
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Function to handle form submission
const handleSubmit = () => {
  // Get the Authentication instance
  const firebaseFirestore = myFirebase.db;

  // Create a reference to a new document in the "contacts" collection
  const contactRef = firebaseFirestore.collection("contacts").doc();

  // Add the data to the document
  contactRef.set({
    name,
    phone,
    email,
    message,
  });

  // Clear the form
  setName("");
  setPhone("");
  setEmail("");
  setMessage("");
};
  return (
    <Box 
      sx={{
        //background: "rgb(250, 250, 250)",
        color: Colors.black,
        pl: { xs: 4, md: 10 },
        pr: { xs: 4, md: 10 },
        pt: 4,
        pb: 4,
        fontSize: { xs: "12px", md: "14px" },
        //width : "80%",           
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item md={6} lg={5}>
          <FooterTitle variant="body1">Let’s work together</FooterTitle>
          <Stack>
            {/* </Stack> style={{ background: "rgb(230,230,230)" }}> */}
            <TextField
              id="name"
              color="primary"
              label="Name"
              variant="standard"
              onChange={(e) => setName(e.target.value)}  // Update state when user types
            />
            <TextField
              id="phone"
              color="primary"
              label="Phone"
              variant="standard"
              onChange={(e) => setPhone(e.target.value)}
            />
            <TextField
              color="primary"
              label="Email address"
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              id="message"
              label="Message"
              multiline
              rows={4}
              //defaultValue="Default Value"
              variant="standard"
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              //startIcon={<SendIcon sx={{ color: Colors.white }} />}
              sx={{ mt: 4, mb: 4 }}
              variant="contained"
              onClick={handleSubmit}  // Call handleSubmit when button is clicked
            >
              Submit
            </Button>
          </Stack>
        </Grid>
        <Grid item md={6} lg={5} justifyContent="center">
          {/* <FooterTitle variant="body1">Contact us</FooterTitle> */}

          <Box
            sx={{
              mt: { md: 4 },
              p: { xs: 2, md: 2 },
              color: Colors.black,
              width: 300,
              height: 300,
              backgroundColor: Colors.light_gray,
              borderRadius: 2,
              //textAlign:"center"
            }}
          >
            <Typography variant="h6"><strong>Contact Information</strong></Typography>
            <br />
            <Typography><strong>Corporate Headquarters</strong></Typography>
            <Typography variant="h9">Nexowave Technology Solutions Private Limited</Typography>
            <br />
            <Typography variant="h9">TR 221, Plot 21 & 21 A, Sector 142, Noida - 201304, India</Typography>
            <br />
            <br />
            <Typography variant="h9"><strong>Phone:</strong> +91-9667746977</Typography>
            <br />
            <Typography variant="h9"><strong>Email:</strong> discover@nexowave.com</Typography>
            <br />
            <br />
            {/* <Typography><strong>Map</strong></Typography> */}
            {/* <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <InstagramIcon /> */}
           
            {/* <FormLabel sx={{ m: 2, fontSize: 25 }}>
              Contact Information
            </FormLabel>
            <br /> */}
            
            {/* <FormLabel sx={{ m: 2, fontSize: 16 }}>
              Corporate Headquarters
            </FormLabel>
            <br /> */}
          
            {/* <FormLabel sx={{ m: 2, fontSize: 13 }}>
              Nexowave Technology Solutions Private Ltd.
            </FormLabel>
            <br /> */}
           
            {/* <FormLabel sx={{ m: 2, fontSize: 13 }}>
              We Work, Sector 16, Noida - 201301
            </FormLabel>
            <br /> */}
           
            {/* <FormLabel sx={{ ml: 2, fontSize: 13, fontWeight: "bold" }}>
              Phone:
            </FormLabel>
            <FormLabel sx={{ ml: 1, fontSize: 13 }}>+91-8826890640</FormLabel>
            <br />
            <FormLabel sx={{ ml: 2, fontSize: 13, fontWeight: "bold" }}>
              Email:
            </FormLabel>
            <FormLabel sx={{ ml: 1, fontSize: 13 }}>
              sales@s4sourcing.in
            </FormLabel> */}
            {/* <br />
            <br />
            <FormLabel sx={{ m: 2, fontSize: 15 }}>
              Map
            </FormLabel> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
