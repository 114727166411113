import './App.css';
import { Container, Typography, Box, Stack } from "@mui/material";
import Appbar from "./components/appbar";
import Banner from "./components/banner";
import Promotions from "./components/promotions";
import Footer from "./components/footer";
import AppDrawer from "./components/drawer";
import SearchBox from "./components/search";
import Industries from "./components/industries";
import Contact from "./components/contact";
import { ThemeProvider } from "@mui/system";
import theme from "./styles/theme"
import { useEffect } from "react";
import { UIProvider } from "./context/ui";
import Product from './components/product';
import Service from './components/service';
import WhatsAppButton from './components/whatsapp';
//import WhatsAppImg from './data/Whatsapp.png'
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  useEffect(() => {
    document.title = "Nexowave - Nextgen Tech Solutions for Business";
  }, []);
  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Nexowave - Nextgen Tech Solutions for Business</title>
        <meta name="description" content="Nexowave offers innovative tech solutions tailored for businesses in various industries." />
        <meta name="keywords" content="Nexowave, Tech Solutions, Business, Innovation, Services, Bizconnekt, Procurement Automation, SaaS Product Development" />
        <meta property="og:title" content="Nexowave - Nextgen Tech Solutions" />
        <meta property="og:description" content="Nexowave offers innovative tech solutions tailored for businesses in various industries." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.nexowave.com" />
        {/* <meta property="og:image" content="https://www.nexowave.com/assets/banner.jpg" /> */}
      </Helmet>
      <Container
        disableGutters
        maxWidth="xl"
        sx={{
          background: "#fff",
        }}
      >
        {" "}
        <Stack>
          <UIProvider> 
            <Appbar />
            <WhatsAppButton />
            <Promotions />
            <Banner />
     
            <Box id="_products" display="flex" justifyContent="center" sx={{ p: 4 }}>
              <Typography variant="h4">Our Products</Typography>
            </Box>
            <Product />
            <Box id="_services" display="flex" justifyContent="center" sx={{ p: 4 }}>
              <Typography variant="h4">Services</Typography>
            </Box>
            <Service />
            <Box display="flex" justifyContent="center" sx={{ p: 4 }}>
              <Typography variant="h4">Industries We Specialize In</Typography>
            </Box>
            <Industries />
            <SearchBox />
            <Container maxWidth="xl" sx={{ background: { xs: "#d5d5d5", md: "fff" }, width: { xs: "90%", md: "80%" }, borderRadius: "2%" }} >
              <Box
                display="flex" justifyContent="center"
                sx={{
                  pl: { xs: 4, md: 10 },
                  pr: { xs: 4, md: 10 },
                  pt: { xs: 4, md: 5 },
                }}
              >
                <Typography variant="h4" id="_contactUs">Contact Us</Typography>
              </Box>
              <Box
                display="flex" justifyContent="center"
                sx={{
                  pl: { xs: 4, md: 10 },
                  pr: { xs: 4, md: 10 },
                  pt: { xs: 4, md: 3 },
                  //background: "rgb(250, 250, 250)",
                  //width: "80%",  
                }}

              >
                <Typography variant="caption2">
                  Please fill out the form below. You can also contact us via
                  email/phone, and our team will gladly handle your request!
                </Typography>
              </Box>
              <Contact />
            </Container>
            <Footer />
            <AppDrawer />
          </UIProvider>
        </Stack>
      </Container>
    </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
