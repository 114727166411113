import { Product, ProductImage } from "../../styles/industry";
import ProductMeta from "./ProductMeta";

export default function SingleProductDesktop({ product, matches }) {
  return (
    <>
      <Product>
        <ProductImage src={product.image} />
      </Product>
      <ProductMeta product={product} />
    </>
  );
}
