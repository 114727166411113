import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import { BannerContainer, BannerContent, BannerDescription, BannerImage, BannerButton, BannerTitle, ColoredTypography } from "../../styles/banner";

export default function Banner() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <BannerContainer>
      <BannerImage src="/images/banner/tech2.jpg" />
      <BannerContent>
        <Typography variant="h5" style={{ display: 'inline-block', }}>
          Simplifying the complex, building next-generation technology solutions
        </Typography>
        <br /><br />
        {/* <ColoredTypography variant="h6" style={{ display: 'inline-block' }}>
          building next-generation technology solutions
        </ColoredTypography> */}
        {/* <BannerTitle variant="h2">Services</BannerTitle> */}
        {/* <BannerDescription variant="subtitle">
          SAAS Solutions Sourcing<br></br>
          Supply Chain Solutions
        </BannerDescription> */}
        <BannerButton color="primary" href="#_contactUs">Get in Touch</BannerButton>
      </BannerContent>
    </BannerContainer>
  );
}
