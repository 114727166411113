import { Typography, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  BannerContainer,
  BannerContent,
  BannerImage,
} from "../../styles/product";

export default function Product() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <BannerContainer>
      <BannerImage src="/images/products/procurement.jpg" />
      <BannerContent>
        {/* <Typography variant="h6">Products</Typography> */}

        <Typography variant="subtitle1">
          <strong>
            {/* <a href="https://www.bizconnekt.com" target="_blank" rel="noopener noreferrer"> */}
              Bizconnekt - Procure Connect Succeed 
            {/* </a> */}
            <br/>
          </strong>
          Procurement Automation Platform
        </Typography>
        <Typography variant="body2" style={{ fontSize: "1rem" }}>
          <a href="https://www.bizconnekt.com" target="_blank" rel="noopener noreferrer">
            Bizconnekt
          </a>{" "}
          is our cutting-edge Procurement Automation Platform developed by Nexowave and exclusively marketed by{" "}
          <a href="https://www.s4sourcing.in" target="_blank" rel="noopener noreferrer">
            S4Sourcing
          </a>. Bizconnekt streamlines the entire O2F process by:
          <ul style={{ fontSize: "1rem" }}>
            <li>Facilitating seamless interactions between buyers and sellers</li>
            <li>Enhancing real-time collaboration</li>
            <li>Improving operational efficiency</li>
          </ul>
          With Bizconnekt, businesses can achieve better transparency, faster communication, and optimized workflows, all while being part of a growing supplier ecosystem.
          <br />
          Explore Bizconnekt:
          <ul style={{ fontSize: "1rem" }}>
            <li>
              <a href="https://www.bizconnekt.com" target="_blank" rel="noopener noreferrer">
                Bizconnekt
              </a>{" "}
              |{" "}
              <a href="https://docs.bizconnekt.com" target="_blank" rel="noopener noreferrer">
                Product Docs
              </a>{" "}
              |{" "}
              <a href="https://app.bizconnekt.com" target="_blank" rel="noopener noreferrer">
                App Portal
              </a>{" "}
              |{" "}
              <a href="https://admin.bizconnekt.com" target="_blank" rel="noopener noreferrer">
                Admin Portal
              </a>
            </li>
          </ul>
        </Typography>

        <br />

        <Typography variant="subtitle1" style={{ fontSize: "1rem" }}>
          <strong>Mini - AI-based Chatbot</strong>
        </Typography>
        <Typography variant="body2" style={{ fontSize: "1rem" }}>
          Mini is our AI-based chatbot system designed to enhance customer engagement, boost lead generation, and streamline sales conversion. Mini also helps automate service management by:
          <ul style={{ fontSize: "1rem" }}>
            <li>Improving customer engagement</li>
            <li>Generating and qualifying leads</li>
            <li>Automating sales conversions</li>
            <li>Managing post-sale service requests</li>
          </ul>
          Mini works as a complementary, intelligent assistant integrated across all our product lines, enhancing business interactions and customer satisfaction.
        </Typography>
      </BannerContent>
    </BannerContainer>
  );
}


