import { Product, ProductImage } from "../../styles/industry";

export default function SingleProduct({ product, matches }) {
  return (
    <>
      <Product>
        <ProductImage src={product.image} />
      </Product>
    </>
  );
}
