export const products = [
  {
    id: 1,
    name: "Manufacturing",
    image: "/images/products/manufacturing.jpg",
  },
  {
    id: 2,
    name: "Supply Chain",
    image: "/images/products/supply-chain-management_IdaDesiMarianaa.png",
  },
  {
    id: 3,
    name: "Technology",
    image: "/images/products/tech_Freepik.png",
  },
  {
    id: 4,
    name: "D2C Brands",
    image: "/images/products/dtc.jpg",
  },
  {
    id: 5,
    name: "Contract Manufacturing",
    image: "/images/products/contractManufacturing.jpg",
  },
  {
    id: 6,
    name: "Logistics",
    image: "/images/products/logistics.jpg",
  },
];