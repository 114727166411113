import { AppbarContainer, AppbarHeader,LogoImage } from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { IconButton } from "@mui/material";
import { useUIContext } from "../../context/ui";

export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  return (
    <AppbarContainer>
      {/* <IconButton onClick={() => setDrawerOpen(true)}>
        <MenuIcon />
      </IconButton> */}
      <LogoImage src="/images/banner/nexoLogo.png"></LogoImage>
      <AppbarHeader textAlign={"left"} variant="h4">
        nexowave
      </AppbarHeader>
      {/* <IconButton onClick={() => setShowSearchBox(true)}>
        <SearchIcon />
      </IconButton> */}
      {/* <Actions matches={matches} /> */}
    </AppbarContainer>
  );
}
