import { Typography, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  BannerContainer,
  BannerContent,
  BannerImage,
} from "../../styles/service";

export default function Service() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BannerContainer>
      <BannerContent>
        <Typography variant="subtitle1">
          <strong>SaaS Product Development</strong>
        </Typography>
        <Typography variant="body2">
        <ul style={{ fontSize: "1rem" }}>
            <li>Custom SaaS product development</li>
            <li>SaaS product maintenance and support</li>
          </ul>
        </Typography>
       <Typography variant="subtitle1">
          <strong>Generative AI Solutions for Customer Engagement</strong>
        </Typography>
        <Typography variant="body2">
        <ul style={{ fontSize: "1rem" }}>
            <li>AI-powered chatbots</li>
            <li>Natural language processing (NLP) solutions</li>
            <li>Text generation solutions</li>
          </ul>
        </Typography>
       <Typography variant="subtitle1">
          <strong>
            Ecommerce Website Development on ONDC Platform and ULIP API's
          </strong>
        </Typography>
        <Typography variant="body2">
        <ul style={{ fontSize: "1rem" }}>
            <li>ONDC-compliant ecommerce website development</li>
            <li>ULIP API integration</li>
          </ul>
          <div style={{ fontSize: "1rem" }}>
          In addition to these services, Nexowave Technology Solutions also
          offers a variety of other services related to product development,
          business commerce, and generative AI. For more information, please
          contact us today.
          </div>
        </Typography>
      </BannerContent>
      <BannerImage src="/images/products/service.jpg" />
    </BannerContainer>
  );
}
