import React from 'react';
import WhatsappIcon from '../../data/Whatsapp.png';
import { Box, styled } from "@mui/system";
import { Colors } from "../../styles/theme";
import WhatsApp from "@mui/icons-material/WhatsApp";
import { IconButton, } from "@mui/material";

const WhatsAppContainer = styled(Box)(({ theme }) => ({ 
  bottom: 50,
  right: 50, 
  zIndex: 90, 
  position: 'fixed',
}));

export default function WhatsAppButton() {
  const openWhatsAppChat = () => {
    // Check if the device is mobile or desktop
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Define the WhatsApp URL based on the device
    let url = '';
    if (isMobile) {
      url = 'whatsapp://send?phone=+919667746977';
    } else {
      url = 'https://web.whatsapp.com/send?phone=+919667746977';
    }

    // Open the WhatsApp chat in a new window or redirect to the app
    window.open(url, '_blank');
  };

  return (
    <WhatsAppContainer>      
      <img src={WhatsappIcon} alt="WhatsApp" onClick={openWhatsAppChat} width="70" />      
    </WhatsAppContainer>
  );
}
